var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{attrs:{"label":_vm.$t('Offers'),"label-for":"pointPurposes"}},[_c('b-overlay',{attrs:{"show":_vm.fetchingPointPurposes && !_vm.pointPurposes.length,"rounded":"","opacity":"0.6"}},[_c('b-form',{ref:"form",staticClass:"repeater-form",staticStyle:{"height":"auto"},attrs:{"id":"pointPurposes"},on:{"submit":function($event){$event.preventDefault();return _vm.repeatAgain($event)}}},[(_vm.pointPurposes.length && _vm.pointPurposes[0].id)?_c('b-badge',{staticClass:"my-1",attrs:{"variant":"light-primary"}},[_c('span',[_vm._v(_vm._s(_vm.getOfferTypeTitle()))])]):_vm._e(),_vm._l((_vm.pointPurposes),function(item,index){return _c('b-row',{key:index,ref:"row",refInFor:true,attrs:{"id":index}},[_c('b-col',{staticClass:"mb-1"},[_c('validation-provider',{attrs:{"name":_vm.$t('Offer'),"vid":("purpose" + index),"rules":"selectionRequired"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:("purpose" + index),refInFor:true,attrs:{"id":("purpose" + index),"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":_vm.$t('I provide services')+':',"label":_vm.pointPurposes[index]?'item_data':'',"filterable":false,"selectable":function (option) { return _vm.isOptionSelectable(option); },"searchable":!_vm.pointPurposes[1] &&!index,"options":_vm.optionsPointPurposes,"loading":_vm.fetchingPointPurposes,"clearable":false},on:{"search":function (search, loading) {
                if(search.length > 2){
                  loading(true)
                  _vm.onSearchDebounced(search, loading)}
              }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var slug = ref.slug;
              var point_type = ref.point_type;
return [(slug)?_c('div',{staticClass:"text-wrap"},[_c('strong',[_vm._v(" "+_vm._s(_vm.getPurposeTitle(slug)))])]):_vm._e(),_c('small',[_vm._v(" ("+_vm._s(_vm.getTypeTitle(point_type.slug))+")")])]}},{key:"selected-option",fn:function(ref){
              var slug = ref.slug;
return [(slug)?_c('div',{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(_vm.getPurposeTitle(slug))+" ")]):_vm._e()]}},{key:"no-options",fn:function(ref){
              var search = ref.search;
              var searching = ref.searching;
              var loading = ref.loading;
return [_c('span'),(search.length && !loading)?_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v(_vm._s(_vm.$t('common.no_results_found_for'))+": "+_vm._s(search))]):_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v(" "+_vm._s(_vm.$t('common.start_typing_to_search')))])]}}],null,true),model:{value:(_vm.pointPurposes[index]),callback:function ($$v) {_vm.$set(_vm.pointPurposes, index, $$v)},expression:"pointPurposes[index]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.ability.can('manage', 'ServiceRequest') || _vm.isManagedUser())?_c('b-form-group',{attrs:{"label":_vm.$t('Tags'),"label-for":"tags"}},[_c('v-select',{ref:("tag" + index),refInFor:true,attrs:{"id":("tag" + index),"multiple":"","taggable":"","push-tags":"","placeholder":"","options":_vm.getTagsOptions(_vm.pointPurposes[index]),"disabled":!_vm.pointPurposes[index].id},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
              var search = ref.search;
              var searching = ref.searching;
return [(searching)?[_vm._v(" "+_vm._s(_vm.$t('common.no_results_found_for'))),_c('em',[_vm._v(" "+_vm._s(search))]),_vm._v(". ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v(_vm._s(_vm.$t('Enter new tags separated by space, comma or semicolon')))])]}}],null,true),model:{value:(_vm.pointPurposes[index].tags),callback:function ($$v) {_vm.$set(_vm.pointPurposes[index], "tags", $$v)},expression:"pointPurposes[index].tags"}})],1):_vm._e()],1),(_vm.isPossibleToAddPurpose())?_c('b-col',{staticClass:"mb-1 ",attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"d-flex justify-content-center justify-content-md-start align-items-center"},[(index)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(40, 199, 111, 0.15)'),expression:"'rgba(40, 199, 111, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1):_vm._e(),(_vm.isPossibleToAddPurpose() && _vm.pointPurposes.length===index+1)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":_vm.repeatAgain}},[_c('feather-icon',{attrs:{"icon":"PlusCircleIcon"}})],1):_vm._e()],1)]):_vm._e()],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }