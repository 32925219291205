<template>

  <!-- form -->

  <b-form-group
    :label="$t('Offers')"
    label-for="pointPurposes"
  >
    <b-overlay
      :show="fetchingPointPurposes && !pointPurposes.length"
      rounded
      opacity="0.6"
    >
      <b-form
        id="pointPurposes"
        ref="form"
        style="height: auto"
        class="repeater-form"
        @submit.prevent="repeatAgain"
      >
        <b-badge
          v-if="pointPurposes.length && pointPurposes[0].id"
          class="my-1"
          variant="light-primary"
        >
          <span>{{ getOfferTypeTitle() }}</span>
        </b-badge>

        <b-row
          v-for="(item, index) in pointPurposes"
          :id="index"
          :key="index"
          ref="row"
        >
          <b-col class="mb-1">
            <validation-provider
              #default="{ errors }"
              :name="$t('Offer')"
              :vid="`purpose${index}`"
              rules="selectionRequired"
            >
              <v-select
                :id="`purpose${index}`"
                :ref="`purpose${index}`"
                v-model="pointPurposes[index]"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('I provide services')+':'"
                :label="pointPurposes[index]?'item_data':''"
                :filterable="false"
                :selectable="(option) => isOptionSelectable(option)"
                :searchable="!pointPurposes[1] &&!index"
                :options="optionsPointPurposes"
                :loading="fetchingPointPurposes"
                :clearable="false"
                @search="(search, loading) => {
                  if(search.length > 2){
                    loading(true)
                    onSearchDebounced(search, loading)}
                }
                "
              >

                <template #option="{ slug, point_type }">
                  <div
                    v-if="slug"
                    class="text-wrap"
                  >
                    <strong> {{ getPurposeTitle(slug) }}</strong>
                  </div>
                  <small> ({{ getTypeTitle(point_type.slug) }})</small>
                </template>
                <template #selected-option="{ slug }">
                  <div
                    v-if="slug"
                    class="text-wrap"
                  >
                    {{ getPurposeTitle(slug) }}
                  </div>
                </template>
                <template v-slot:no-options="{ search, searching,loading }">
                  <span />
                  <em
                    v-if="search.length && !loading"
                    style="opacity: 0.5"
                  >{{ $t('common.no_results_found_for') }}: {{ search }}</em>
                  <em
                    v-else
                    style="opacity: 0.5"
                  > {{ $t('common.start_typing_to_search') }}</em>

                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <b-form-group
              v-if="ability.can('manage', 'ServiceRequest') || isManagedUser()"
              :label="$t('Tags')"
              label-for="tags"
            >
              <v-select
                :id="`tag${index}`"
                :ref="`tag${index}`"
                v-model="pointPurposes[index].tags"
                multiple
                taggable
                push-tags
                placeholder=""
                :options="getTagsOptions(pointPurposes[index])"
                :disabled="!pointPurposes[index].id"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    {{ $t('common.no_results_found_for') }}<em> {{ search }}</em>.
                  </template>

                  <em
                    v-else
                    style="opacity: 0.5"
                  >{{ $t('Enter new tags separated by space, comma or semicolon') }}</em>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col
            v-if="isPossibleToAddPurpose()"
            cols="12"
            md="4"
            class="mb-1 "
          >
            <div class="d-flex justify-content-center justify-content-md-start align-items-center">
              <b-button
                v-if="index"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="outline-danger"
                class="mr-1"
                @click="removeItem(index)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
              <b-button
                v-if="isPossibleToAddPurpose() && pointPurposes.length===index+1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                @click="repeatAgain"
              >
                <feather-icon
                  icon="PlusCircleIcon"
                />

              </b-button>
            </div>

          </b-col>
        </b-row>
      </b-form>

    </b-overlay>
  </b-form-group>

</template>

<script>
import {
  BFormGroup, BRow, BCol, BForm, BButton, BOverlay, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, extend } from 'vee-validate'
import {
  required, confirmed, min,
} from '@validations'
import vSelect from 'vue-select'
import { locales, serviceOptions } from '@/mixins/options'
import { ref, watch } from '@vue/composition-api'
import { usePointRemoteData } from '@/views/apps/service/usePoints'
import i18n from '@/libs/i18n'
import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import { useUserUi } from '@/views/apps/user/useUser'
import { useDebounceFn } from '@vueuse/core'
import { heightTransition } from '@core/mixins/ui/transition'

extend('selectionRequired', {
  validate(value) {
    return value ? !!value.id : false
  },
  message: (_, values) => i18n.t('validations.messages.selection_required', values),
})
export default {
  components: {
    ValidationProvider,
    vSelect,
    BFormGroup,
    BRow,
    BCol,
    BForm,
    BButton,
    BOverlay,
    BBadge,

  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    data: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {

      required,
      confirmed,
      min,
    }
  },

  setup(props) {
    const pointData = ref({})
    const pointType = ref({})
    const optionsPointPurposes = ref([])
    const fetchingPointPurposes = ref(false)
    const optionsTags = ref([])
    const pointPurposes = ref([])
    const nextTodoId = ref(0)
    const {
      localesList,
    } = locales()
    const optionLanguages = localesList
    const {
      getTypeTitle, getTranslatedPointPurposes, getPurposeTitle,
    } = serviceOptions
    const { fetchPointPurposes } = usePointRemoteData()

    const tagValidator = tag => tag === tag.toLowerCase() && tag.length > 2 && tag.length < 16

    const ability = defineAbilityForCurrentUser()

    const setOptionsPointPurposes = (args, data) => {
      const optionsList = data
      optionsList.map(el => {
        el.item_data = [el.title, args.q].join(' ')
        return el.item_data
      })
      optionsPointPurposes.value = optionsList

      return optionsPointPurposes.value
    }

    async function fetchFilteredPurposesByPointType(data) {
      fetchingPointPurposes.value = true
      const args = {
        point_type_ids: ('point_type' in data) ? data.point_type.id : data.point_type_id,
        lang: data.lang,
      }

      return new Promise((resolve, reject) => {
        fetchPointPurposes(args)
          .then(response => {
            setOptionsPointPurposes(args, response.data.data)
            return resolve(true)
          })
          .catch(error => reject(error)).finally(() => {
            fetchingPointPurposes.value = false
          })
      })
    }

    const onSearchDebounced = useDebounceFn(async (search, loading) => {
      const args = {
        q: search,
        lang: i18n.locale,
      }

      return fetchPointPurposes(args)
        .then(async response => setOptionsPointPurposes(args, response.data.data))
        .catch(error => console.log(error)).finally(() => loading(false))
    }, 1000)
    const { isManagedUser } = useUserUi()

    const repeatAgain = () => {
      nextTodoId.value += 1
      pointPurposes.value.push({
        id: '',
        item_data: '',
        tags: [],
        point_type_id: '',
      })
    }

    watch(
      () => props.data,
      () => {
        pointData.value = props.data
        if (pointData.value.point_type && pointData.value.point_type.id) {
          fetchFilteredPurposesByPointType(pointData.value).then(() => {
            const purposes = pointData.value.point_purposes.map(el => el.id)
            pointPurposes.value = JSON.parse(JSON.stringify(optionsPointPurposes.value.filter(el => purposes.includes(el.id))))
            pointPurposes.value.forEach(el => {
              el.point_type = pointData.value.point_type
              el.tags = pointData.value.point_purposes.find(p => p.id === el.id).tags
            })
            pointType.value = pointData.value.point_type
          })
        } else {
          repeatAgain()
        }
      }, { immediate: true },
    )
    const removeItem = index => {
      pointPurposes.value.splice(index, 1)
      pointData.value.point_purposes.splice(index, 1)
    }
    watch(
      () => pointPurposes.value,
      newValue => {
        if (!newValue[0]) return

        if (!('point_type' in newValue[0])) return

        const pointTypeId = newValue[0].point_type.id

        pointData.value.point_type_id = pointTypeId
        pointData.value.point_purposes = pointPurposes.value.map(item => ({ id: item.id, tags: item.tags }))

        if (newValue.length > 1) return
        if (pointType.value.id === pointTypeId) return

        pointType.value.id = pointTypeId
        fetchFilteredPurposesByPointType(pointData.value)
      }, { deep: true },

    )
    watch(
      () => i18n.locale,
      newValue => {
        if (pointData.value.lang !== newValue) {
          pointData.value.lang = newValue
          fetchFilteredPurposesByPointType(pointData.value)
        }

        pointPurposes.value.forEach(el => {
          el.tags = []
        })
      },

    )

    const getOfferTypeTitle = () => (pointPurposes.value[0].point_type ? getTypeTitle(pointPurposes.value[0].point_type.slug) : '')
    const getTagsOptions = item => {
      if (!item.id) return
      let tags = []
      const obj = optionsPointPurposes.value.filter(el => el.id === item.id)
      if (obj.length && obj[0].tags) {
        tags = obj[0].tags
      }
      return tags
    }

    const isOptionSelectable = option => {
      if (!pointData.value.point_purposes) return true
      return (pointData.value.point_purposes.findIndex(el => el.id === option.id) < 0)
    }
    const isPossibleToAddPurpose = () => (!fetchingPointPurposes.value && pointType.value.id)

    return {
      pointData,
      pointPurposes,
      getTranslatedPointPurposes,
      optionsPointPurposes,
      fetchFilteredPurposesByPointType,
      fetchingPointPurposes,
      getTypeTitle,
      getPurposeTitle,
      optionLanguages,
      optionsTags,
      tagValidator,
      ability,
      isManagedUser,
      onSearchDebounced,
      isOptionSelectable,
      isPossibleToAddPurpose,
      repeatAgain,
      removeItem,
      getOfferTypeTitle,
      getTagsOptions,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
